<template>
  <div class="address-line">
    <span v-if="address">
      <span v-if="address.street && (address.zip || address.city)">
        {{ address.street }},&nbsp;
      </span>
      <span v-else>{{ address.street }}&nbsp;</span>
      <span v-if="address.country && address.zip">{{ address.country }}-</span>
      <span v-if="address.zip">{{ address.zip }}</span>
      <span v-if="address.city">&nbsp;{{ address.city }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: ["address"]
};
</script>

<style>
.address-line {
  font-size: 12px;
}
</style>
