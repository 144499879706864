var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tenants-list mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v(" Mandanten ")])]),_c('v-col',{staticClass:"text-sm-right"},[_c('tooltip-button',{attrs:{"icon":"add","text":"Mandant erfassen","color":"primary","classNames":"ml-3","position":"top"},on:{"click":function($event){return _vm.openCreateDialog()}}})],1)],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[_c('remote-data-table',{ref:"tenantsDatatable",attrs:{"url":'backoffice/tenants',"search-enabled":true,"headers":_vm.headers,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.canBeModified(item.short_name))?_c('router-link',{attrs:{"to":("/tenants/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.canBeModified(item.short_name))?_c('router-link',{attrs:{"to":("/tenants/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.primary_address",fn:function(ref){
var item = ref.item;
return [_c('address-line',{attrs:{"address":item.primary_address}})]}},{key:"item.billing_address",fn:function(ref){
var item = ref.item;
return [_c('address-line',{attrs:{"address":item.billing_address}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"disabled":!_vm.canBeModified(item.short_name),"icon":"delete","text":"Löschen","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.openDeleteDialog(item.id, item.name)}}})]}}])})],1)],1)],1),_c('tenant-dialog',{ref:"tenantDialog"}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.tenantsDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }