<template>
  <div class="tenants-list mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Mandanten
          </h1>
        </v-col>
        <v-col class="text-sm-right">
          <tooltip-button
            icon="add"
            text="Mandant erfassen"
            color="primary"
            @click="openCreateDialog()"
            classNames="ml-3"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/tenants'"
            :search-enabled="true"
            :headers="headers"
            sort-by="name"
            ref="tenantsDatatable"
          >
            <template v-slot:item.id="{ item }">
              <router-link
                v-if="canBeModified(item.short_name)"
                :to="`/tenants/${item.id}`"
              >
                {{ item.id }}
              </router-link>
              <span v-else>{{ item.id }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <router-link
                v-if="canBeModified(item.short_name)"
                :to="`/tenants/${item.id}`"
              >
                {{ item.name }}
              </router-link>
              <span v-else>{{ item.name }}</span>
            </template>
            <template v-slot:item.primary_address="{ item }">
              <address-line :address="item.primary_address" />
            </template>
            <template v-slot:item.billing_address="{ item }">
              <address-line :address="item.billing_address" />
            </template>
            <template v-slot:item.action="{ item }">
              <tooltip-button
                :disabled="!canBeModified(item.short_name)"
                icon="delete"
                text="Löschen"
                color="default"
                :dark="false"
                @click="openDeleteDialog(item.id, item.name)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <tenant-dialog ref="tenantDialog" />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.tenantsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import TenantDialog from "@/components/dialogs/TenantDialog";
import TooltipButton from "@/components/forms/TooltipButton";
import RemoteDataTable from "@/components/RemoteDataTable";
import AddressLine from "@/components/forms/AddressLine.vue";

export default {
  name: "tenants-list",

  components: {
    DeleteDialog,
    TenantDialog,
    TooltipButton,
    RemoteDataTable,
    AddressLine
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      {
        text: "Kürzel",
        align: "left",
        sortable: true,
        value: "short_name"
      },
      { text: "Email", align: "left", sortable: true, value: "email" },
      {
        text: "Hauptaddresse",
        align: "left",
        sortable: true,
        value: "primary_address"
      },
      {
        text: "Rechnungsadresse",
        align: "left",
        sortable: true,
        value: "billing_address"
      },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ],
    search: null
  }),

  methods: {
    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.tenantDialog.mode = "create";
      this.$refs.tenantDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "tenants";
      this.$refs.deleteDialog.open = true;
    },

    /**
     * Check if tenant can be modified.
     */
    canBeModified(id) {
      return ["tingo", "default"].indexOf(id) < 0;
    }
  }
};
</script>

<style lang="scss">
.tenants-list {
  a {
    text-decoration: none;
    color: var(--v-primary-darken1);
  }
}
</style>
