<template>
  <v-dialog v-model="open" scrollable width="700">
    <v-form v-model="valid" @submit.prevent="save()">
      <v-card v-if="tenant">
        <v-card-title class="primary white--text">
          Mandant erfassen
        </v-card-title>

        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="tenant.name"
                label="Name"
                type="text"
                :maxlength="255"
                :rules="textRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    valid: false,
    tenant: null,
    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        this.resetForm();
      }
    }
  },

  computed: {
    ...mapState("app", ["configs"])
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.tenant = {
        name: null
      };
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save tenant.
     */
    save() {
      this.$api.http
        .post(`backoffice/tenants`, this.tenant)
        .then(response => {
          this.$router.push({ path: `/tenants/${response.data.id}` });
        })
        .catch(error => {
          this.$toast.error("Der Mandant konnte nicht eröffnet werden.");
        });
    }
  }
};
</script>
